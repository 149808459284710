/* purgecss start ignore */
@tailwind base;
@tailwind components;
/* purgecss end ignore */
@tailwind utilities;

.pill-yellow {
  @apply px-2 mr-1 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-200 text-gray-800 border;
}

.btn-gray {
  @apply bg-gray-700 text-white font-bold py-2 px-4 mt-4 rounded;
}

.bg-accent {
  @apply bg-green-700;
}

.bg-accent {
  @apply bg-green-900;
}

.text-accent {
  @apply text-green-700;
}

.text-accent:hover {
  @apply text-green-900;
}

.ring-accent {
  @apply ring-green-700;
}

.ring-accent:focus {
  @apply ring-green-700;
}

.border-accent {
  @apply border-green-700;
}

.border-accent:focus {
  @apply border-green-700;
}

.btn-gray:hover {
  @apply bg-gray-500;
}

.btn-gray:focus {
  @apply outline-none ring;
}

.btn-gray-sm {
  @apply bg-gray-700 text-white py-1 px-4 rounded;
}

.btn-gray-sm:hover {
  @apply bg-gray-500;
}

.btn-gray-sm:focus {
  @apply outline-none ring;
}

.card-item {
  @apply my-2 border border-gray-500 bg-gray-100;
}

.card-item:hover {
  @apply bg-gray-200 cursor-pointer;
}

.card-body {
  @apply bg-gray-100;
}

.landing-copy {
  @apply my-2;
}

.landing-link {
  @apply text-blue-600 underline;
}

.markdown-blob ul {
  @apply list-disc list-inside sm:list-outside;
}

.markdown-blob ol {
  @apply list-decimal list-inside sm:list-outside;
}

.markdown-blob a {
  @apply text-blue-700;
}

.markdown-blob a:hover {
  @apply text-blue-500;
}

.markdown-blob h1 {
  @apply text-3xl mt-4 mb-1;
}

.markdown-blob h2 {
  @apply text-3xl mt-4 mb-1;
}

.markdown-blob h3 {
  @apply text-2xl mt-4 mb-1;
}

.markdown-blob h4 {
  @apply text-xl mt-4 mb-1;
}

.markdown-blob h5 {
  @apply text-xl mt-4 mb-1;
}
